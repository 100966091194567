// src/App.tsx
import { NextUIProvider } from '@nextui-org/react';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import Login from './components/login/Login';
import Signup from './components/login/Signup';
import SearchPage from './components/SearchPage';

const App: React.FC = () => {
  const darkMode = useDarkMode(false);
  const theme = darkMode.value ? 'dark' : '';
  return (
    <React.StrictMode>
      <NextUIProvider>
        <main
          className={`${theme} text-foreground bg-background w-max-full h-max-full min-w-screen min-h-screen`}
        >
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/search" element={<SearchPage />} />
            </Routes>
          </Router>
        </main>
      </NextUIProvider>
    </React.StrictMode>
  );
};

export default App;
