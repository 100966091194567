// src/components/SearchPage.tsx
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Category,
  Conversation,
  createConversation,
  deleteConversation,
  getCategories,
  getNewsReport,
  getSources,
  getUser,
  getUserConversations,
  getUserFeed,
  logoutUser,
  Update,
  User,
} from '../services/api';

import { Card, CardBody, CardHeader, Chip } from '@nextui-org/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import CustomAudioPlayer from './CustomAudioPlayer';
import FeedComponent from './feed/Feed';
import NavBar from './navbar/Navbar';

const SearchPage: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [conversations, setConversations] = useState<Record<string, Conversation[]>>({});
  const [categories, setCategories] = useState<Category[]>([]);
  const [feed, setFeed] = useState<Update[]>([]);
  const [, setError] = useState<string | null>(null);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const [loadSearch, setLoadSearch] = useState(false);
  const [showPlay, setShowPlay] = useState(true);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const navigate = useNavigate();
  const token = Cookies.get('token');

  const toggleConversations = () => {
    setShowConversations(!showConversations);
  };

  const fetchConversations = useCallback(async () => {
    try {
      let userConversations = await getUserConversations(token ?? '');
      userConversations = _.sortBy(userConversations, 'createdAt');
      setConversations(
        _.groupBy(userConversations, 'topic.categoryId') as unknown as Record<
          string,
          Conversation[]
        >,
      );
    } catch (err) {
      setError('Failed to fetch user conversations. Please log in again.');
      localStorage.removeItem('token');
      navigate('/');
    }
  }, [navigate, token]);

  const addSourcesToFeed = useCallback(async (feed: Update[]) => {
    const sourceIds = _.flattenDeep(feed.map(update => update.sources));
    const sources = await getSources(sourceIds);
    const keyedSources = _.keyBy(sources, 'id');
    feed.forEach(update => {
      update.sourceObjects = update.sources.map(sourceId => keyedSources[sourceId]);
    });
    return feed;
  }, []);

  const fetchCategories = useCallback(async () => {
    const categories = await getCategories(token ?? '');
    setCategories(categories);
  }, [token]);

  const fetchFeed = useCallback(async () => {
    try {
      let userFeed = await getUserFeed(token ?? '');
      userFeed = await addSourcesToFeed(userFeed);
      setFeed(userFeed);
    } catch (err) {
      setError('Failed to fetch user feed. Please log in again.');
      localStorage.removeItem('token');
      navigate('/');
    }
  }, [addSourcesToFeed, navigate, token]);

  const getNextPage = useCallback(async () => {
    setIsFetchingNextPage(true);

    try {
      const nextUserFeed = await getUserFeed(token ?? '', feed.length);
      let newFeed = feed.concat(nextUserFeed);
      newFeed = await addSourcesToFeed(newFeed);
      newFeed = _.uniqBy(newFeed, 'id');
      setFeed(newFeed);
    } catch (err) {
      setError('Failed to fetch next page. Please log in again.');
    } finally {
      setIsFetchingNextPage(false);
    }
  }, [addSourcesToFeed, feed, token]);

  const fetchUser = useCallback(async () => {
    try {
      const userData = await getUser(token ?? '');
      setUser(userData);
    } catch (err) {
      setError('Failed to fetch user data. Please log in again.');
      localStorage.removeItem('token');
      navigate('/');
    }
  }, [navigate, token]);

  const handleScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop; // Distance scrolled from top
    const scrollHeight = document.documentElement.scrollHeight; // Total height of the page
    const clientHeight = document.documentElement.clientHeight; // Visible height of the browser window

    if (scrollTop + clientHeight >= scrollHeight - 50 && !isFetchingNextPage) {
      getNextPage();
    }
  }, [isFetchingNextPage, getNextPage]);

  useEffect(() => {
    // Redirect to login page if no token is found
    if (!token) {
      navigate('/');
      return;
    }

    // Fetch the current user details using the token
    fetchUser();
    fetchCategories();
    fetchConversations();
    fetchFeed();
    if (loadSearch === true) {
      setLoadSearch(false);
    }
  }, [loadSearch, fetchCategories, fetchConversations, fetchFeed, fetchUser, token, navigate]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [feed, isFetchingNextPage, handleScroll]);

  // Handle the logout process
  const handleLogout = async () => {
    try {
      if (token) {
        await logoutUser(token);
        localStorage.removeItem('token');
        navigate('/');
      }
    } catch (err) {
      setError('Failed to log out. Please try again.');
    }
  };

  // Handle the search query submission
  const handleSearch = async (searchQuery: string, categoryId: string) => {
    if (!searchQuery.trim()) {
      setError('Please enter a search term.');
      return;
    }

    try {
      await createConversation(searchQuery, token as string, categoryId);
      await fetchConversations();
      await fetchFeed();
    } catch (err) {
      setError('Failed to create. Please try again.');
    }
  };

  const playReport = async () => {
    const url = await getNewsReport(
      feed
        .filter(f => dayjs(f.createdAt).isAfter(dayjs().subtract(1, 'day')))
        .map(update => update.id),
      token as string,
    );
    setAudioUrl(url);
    setShowPlay(false);
  };

  const handleChipDelete = async (id: string) => {
    await deleteConversation(id, token as string);
    await fetchConversations();
    await fetchFeed();
  };

  return (
    <div className="w-full h-full px-3 ">
      <NavBar
        user={user}
        logoutHandler={handleLogout}
        categories={categories}
        searchHandler={handleSearch}
        conversations={conversations}
        toggleConversations={toggleConversations}
        loadSearch={setLoadSearch}
        playReport={playReport}
        showPlay={audioUrl === null ? showPlay : false}
      />
      {audioUrl !== null ? (
        <div className="pt-3">
          <CustomAudioPlayer audioSrc={audioUrl} />
        </div>
      ) : (
        <></>
      )}
      <div
        className="flex flex-wrap gap-2 pt-3"
        style={{ display: showConversations ? 'flex' : 'none' }}
      >
        {Object.keys(conversations).map((key, index) => (
          <Card key={index}>
            <CardHeader className="capitalize">
              {categories.find(category => category.id === key)?.value}
            </CardHeader>
            <CardBody className="flex flex-row flex-wrap gap-2 pt-3">
              {conversations[key].map((conversation, index) => (
                <Chip key={index} onClose={() => handleChipDelete(conversation.id)} variant="flat">
                  {conversation.name}
                </Chip>
              ))}
            </CardBody>
          </Card>
        ))}
      </div>
      <div className="flex flex-wrap gap-2 pt-6 space-y-4">
        {feed.map((update, index) => (
          <FeedComponent
            feed={update}
            category={categories.find(category => category.id === update.topic.categoryId)}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchPage;
