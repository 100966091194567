import { Button, Card, CardBody, Slider } from '@nextui-org/react';
import { useEffect, useRef, useState } from 'react';
import { PlayButtonIcon } from './navbar/Navbar';

export const PauseButtonIcon = ({
  size = 24,
  color = 'currentColor',
  ...props
}: {
  size?: number;
  color?: string;
  [key: string]: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke={color}
    width={size}
    height={size}
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M10 9v6m4-6v6" />
  </svg>
);

const CustomAudioPlayer = ({ audioSrc }: { audioSrc: string }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (audioRef.current) {
        setDuration(audioRef.current.duration);
        audioRef.current.play(); // Autoplay when metadata is loaded
      }
    };

    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  const togglePlay = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (e: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = e;
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Card className="w-full">
      <audio
        style={{ display: 'none' }}
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        preload="metadata"
        autoPlay
      />
      <CardBody className="flex flex-row space-x-4 items-center">
        <Button isIconOnly variant="light" className="w-5 h-6" onClick={togglePlay}>
          {isPlaying ? <PauseButtonIcon /> : <PlayButtonIcon />}
        </Button>
        <Slider
          size="sm"
          value={currentTime}
          maxValue={duration}
          onChange={value => handleSeek(value as number)}
          color="primary"
          showOutline={false}
          step={1}
        />
        <div className="flex flex-col items-end">
          <p>{formatTime(currentTime)}</p>
        </div>
      </CardBody>
    </Card>
  );
};

export default CustomAudioPlayer;
