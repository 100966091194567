// src/services/api.ts
import axios from 'axios';
import _ from 'lodash';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  username: string;
}

export interface Topic {
  id: string;
  query: string;
  lastFetchedDate: string;
  icon: string;
  categoryId: string;
}

export interface Update {
  id: string;
  createdAt: string;
  topicId: number;
  body: string;
  sources: string[];
  sourceObjects: Source[];
  topic: Topic;
  title: string;
  points: string[];
}

export interface Conversation {
  id: string;
  icon: string;
  userId: number;
  topic: Topic;
  name: string;
}

export interface Source {
  createdDate: string;
  id: string;
  url: string;
  source: string;
  sourceMedia: SourceMedia[];
}

export interface SourceMedia {
  id: string;
  url: string;
}

export interface Category {
  id: string;
  value: string;
  context: string;
  language: string;
  active: boolean;
}

export interface ApiResponse {
  summary: string;
  sources: Array<{ title: string; url: string }>;
}

export interface Keywords {
  id: string;
  word: string;
}

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Function to create a new user
export const createUser = async (username: string, password: string) => {
  const response = await api.post('/user', { username, password });
  return response.data;
};

// Function to login user and get JWT token
export const loginUser = async (username: string, password: string) => {
  const response = await api.post('/user/login', { username, password });
  return response.data;
};

// Function to get the current logged-in user
export const getUser = async (token: string) => {
  const response = await api.get('/user', {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data;
};

// Function to logout user
export const logoutUser = async (token: string) => {
  return await api.post(
    '/user/logout',
    {},
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
};

export const getCategories = async (token: string): Promise<Category[]> => {
  const response = await api.get('/category', {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data;
};

export const createConversation = async (
  query: string,
  token: string,
  categoryId?: string,
): Promise<void> => {
  await api.post(
    '/conversation',
    { q: query, categoryId: categoryId },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
};

export const updateConversation = async (id: string, token: string): Promise<void> => {
  await api.post(
    '/conversation/update',
    { id },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
};

export const deleteConversation = async (id: string, token: string): Promise<void> => {
  await api.delete('/conversation', {
    headers: {
      Authorization: `${token}`,
    },
    data: {
      id,
    },
  });
};

export const getConversationById = async (id: string, token: string): Promise<Conversation> => {
  const response = await api.post(
    '/conversation/id',
    { id },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );

  return response.data;
};

export const getUserConversations = async (token: string) => {
  const response = await api.get('/user/conversations', {
    headers: {
      Authorization: `${token}`,
    },
  });
  return response.data;
};

export const getUserFeed = async (token: string, skip = 0, limit = 10) => {
  const response = await api.get('/user/feed', {
    headers: {
      Authorization: `${token}`,
    },
    params: { skip, limit },
  });
  return response.data;
};

export const getSources = async (ids: string[]) => {
  const chunkedIds = _.chunk(ids, 50);
  const sources = [];
  for (const chunk of chunkedIds) {
    const response = await api.post('/source/ids', { ids: chunk });
    sources.push(...response.data);
  }
  return sources;
};

export const createUpdateFlag = async (
  updateId: string,
  reason: string,
  token: string,
): Promise<void> => {
  await api.post(
    '/flag',
    { updateId, reason },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
};

export const getNewsReport = async (ids: string[], token: string): Promise<string> => {
  const response = await api.post(
    '/update/report',
    { ids },
    {
      headers: {
        Authorization: `${token}`,
      },
      responseType: 'blob',
    },
  );

  return URL.createObjectURL(response.data);
};

export const fetchKeywords = async (id: string, token: string): Promise<Keywords[]> => {
  const res = await api.post(
    '/category/keywords',
    { id },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
  return res.data as Keywords[];
};
