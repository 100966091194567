import Cookies from 'js-cookie'; // To store tokens in cookies instead of local storage
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../../services/api';
import NavBar from '../navbar/Navbar';
import { LoginCard } from './LoginCard';

const Signup: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [, setError] = useState('');
  const navigate = useNavigate();

  // Handle account creation and login
  const handleCreateAccount = async () => {
    try {
      const data = await createUser(username, password);

      // Save the tokens in cookies instead of local storage
      Cookies.set('token', data.jwt, { expires: 7 }); // Expires in 7 days
      Cookies.set('refreshToken', data.refreshToken, { expires: 7 });

      navigate('/search');
    } catch (err) {
      setError('Failed to create account');
    }
  };

  return (
    <div className="w-full h-screen">
      <NavBar />
      <div className="w-full h-full justify-center items-center flex">
        <LoginCard
          setUsername={setUsername}
          setPassword={setPassword}
          handleLogin={handleCreateAccount}
          header="Create Account"
          buttonName="Sign Up"
          showCreateAccount={false}
        />
      </div>
    </div>
  );
};

export default Signup;
