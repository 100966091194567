import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import Cookies from 'js-cookie';
import useDarkMode from 'use-dark-mode';
import { createUpdateFlag } from '../../services/api';

export const FlagIcon = ({ size = 24, strokeWidth = 1.5, width, height, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={width || size}
    height={height || size}
    {...props}
  >
    <path
      d="M4 21V3"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4H17.5C18.3284 4 19 4.67157 19 5.5V12.5C19 13.3284 18.3284 14 17.5 14H4"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Flag = ({ updateId }: { updateId: string }) => {
  const { value } = useDarkMode();
  const theme = value ? 'dark' : 'light';
  const token = Cookies.get('token');

  const flagUpdate = (key: any) => {
    createUpdateFlag(updateId, key, token!);
  };

  return (
    <div>
      <Dropdown className={`${theme}`}>
        <DropdownTrigger>
          <Button isIconOnly variant="light" className="w-5 h-6">
            <FlagIcon />
          </Button>
        </DropdownTrigger>
        <DropdownMenu aria-label="Static Actions" onAction={flagUpdate}>
          <DropdownItem className="text-danger" color="danger" key="inappropriate">
            Inappropriate Content
          </DropdownItem>
          <DropdownItem className="text-danger" color="danger" key="wrong">
            Wrong Content
          </DropdownItem>
          <DropdownItem className="text-danger" color="danger" key="redundant">
            Redundant Content
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Flag;
