import { Button, Card, CardFooter, Input, Link } from '@nextui-org/react';
import React from 'react';
import { EyeFilledIcon, EyeSlashFilledIcon } from './Login';

export const LoginCard = ({
  setUsername,
  setPassword,
  handleLogin,
  header,
  buttonName,
  showCreateAccount,
}: any) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <Card className="rounded-lg p-8 max-w-sm w-full">
      <div className="flex justify-center mb-4">
        <span className="text-yellow-400 text-2xl">✨</span>
      </div>
      <h2 className="text-center text-2xl font-bold mb-6">{header}</h2>
      <Input className="mb-4" size="sm" type="email" label="Email" onValueChange={setUsername} />
      <Input
        className="mb-6"
        label="Password"
        variant="bordered"
        placeholder="Enter your password"
        onValueChange={setPassword}
        endContent={
          <button
            className="focus:outline-none"
            type="button"
            onClick={toggleVisibility}
            aria-label="toggle password visibility"
          >
            {isVisible ? (
              <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
            ) : (
              <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
            )}
          </button>
        }
        type={isVisible ? 'text' : 'password'}
      />

      <Button color="primary" onClick={handleLogin}>
        {buttonName}
      </Button>

      {showCreateAccount ? (
        <CardFooter className="mt-6 text-center justify-center">
          <Link href="/signup" underline="hover">
            Create an account
          </Link>
        </CardFooter>
      ) : null}
    </Card>
  );
};
