import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  Select,
  SelectItem,
  useDisclosure,
} from '@nextui-org/react';
import Cookies from 'js-cookie';
import React from 'react';
import useDarkMode from 'use-dark-mode';
import { Category, Conversation, User } from '../../services/api';
import SearchModel from './SearchModel';
import { ThemeSwitcher } from './ThemeSwitcher';

export const SearchIcon = ({ size = 24, strokeWidth = 1.5, width, height, ...props }: any) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height={height || size}
    role="presentation"
    viewBox="0 0 24 24"
    width={width || size}
    {...props}
  >
    <path
      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M22 22L20 20"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export const PlayButtonIcon = ({
  size = 24,
  color = 'currentColor',
  ...props
}: {
  size?: number;
  color?: string;
  [key: string]: any;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    {...props}
  >
    <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" fill="none" />
    <polygon points="10,8 16,12 10,16" fill={color} />
  </svg>
);

export const ConversationIcon = ({
  size = 24,
  strokeWidth = 1.5,
  width,
  height,
  ...props
}: any) => (
  <svg
    aria-hidden="true"
    fill="none"
    focusable="false"
    height={height || size}
    role="presentation"
    viewBox="0 0 24 24"
    width={width || size}
    {...props}
  >
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.9344 3.59785 15.7087 4.62903 17.1574L3 21L6.8426 19.371C8.29132 20.4022 10.0656 21 12 21Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M9 11H15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M9 15H13"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M9 7H15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default function NavBar({
  user = null,
  logoutHandler,
  categories = [],
  searchHandler,
  conversations = {},
  toggleConversations,
  loadSearch,
  playReport,
  showPlay = true,
}: {
  user?: User | null;
  logoutHandler?: () => void;
  categories?: Category[];
  searchHandler?: (searchQuery: string, categoryId: string) => void;
  conversations?: Record<string, Conversation[]>;
  toggleConversations?: () => void;
  loadSearch?: (bool: boolean) => void;
  playReport?: () => void;
  showPlay?: boolean;
}) {
  let { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { value } = useDarkMode();
  const theme = value ? 'dark' : 'light';
  const [summaryType, setSummaryType] = React.useState<string>('');

  React.useEffect(() => {
    const summaryType = Cookies.get('summaryType');
    if (summaryType) {
      setSummaryType(summaryType);
    } else {
      setSummaryType('summary');
      Cookies.set('summaryType', 'summary');
    }
  }, []);

  const updateSummaryType = async (type: string) => {
    Cookies.set('summaryType', type);
    if (loadSearch !== undefined) {
      loadSearch(true);
    }
  };

  const handleSummarySelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSummaryType(e.target.value);
    updateSummaryType(e.target.value);
  };

  return (
    <div>
      <Navbar isBordered maxWidth="full">
        <NavbarContent justify="start">
          <NavbarBrand className="mr-2">
            <p className="sm:block font-bold text-inherit">Topiq</p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent as="div" className="flex flex-row space-x-0" justify="end">
          {user !== null && user !== undefined ? (
            <>
              {showPlay ? (
                <Button isIconOnly variant="light" onClick={playReport}>
                  <PlayButtonIcon />
                </Button>
              ) : (
                <></>
              )}
              <Button isIconOnly variant="light" onClick={toggleConversations}>
                <ConversationIcon />
              </Button>
              <Button isIconOnly variant="light" onClick={onOpen}>
                <SearchIcon />
              </Button>
              <SearchModel
                isOpen={isOpen}
                onOpen={onOpen}
                onOpenChange={onOpenChange}
                categories={categories}
                searchHandler={searchHandler}
                theme={theme}
              />
              <Dropdown placement="bottom-end" className={`${theme}`}>
                <DropdownTrigger>
                  <Avatar
                    isBordered
                    as="button"
                    className="transition-transform"
                    color="default"
                    size="sm"
                  />
                </DropdownTrigger>
                <DropdownMenu variant="flat">
                  <DropdownItem key="profile" isReadOnly>
                    <p className="font-semibold">{user.username}</p>
                  </DropdownItem>

                  <DropdownItem
                    isReadOnly
                    key="summaryType"
                    className="cursor-default"
                    endContent={
                      <Select
                        id="summaryType"
                        name="summaryType"
                        defaultSelectedKeys={[summaryType]}
                        onChange={handleSummarySelectionChange}
                        variant="flat"
                        color="default"
                      >
                        <SelectItem textValue="summary" key="summary">
                          summary
                        </SelectItem>
                        <SelectItem textValue="points" key="points">
                          points
                        </SelectItem>
                      </Select>
                    }
                  >
                    Format
                  </DropdownItem>

                  <DropdownItem isReadOnly endContent={<ThemeSwitcher />}>
                    Theme
                  </DropdownItem>

                  <DropdownItem key="logout" color="danger" onClick={logoutHandler}>
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <></>
          )}
        </NavbarContent>
      </Navbar>
    </div>
  );
}
