import {
  Avatar,
  AvatarGroup,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Image,
  Tooltip,
} from '@nextui-org/react';

import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { Category, Update } from '../../services/api';
import Flag from './Flag';

const FeedComponent = ({ feed, category }: { feed: Update; category?: Category }) => {
  const getTimeString = (time: string) => {
    const parsedTime = dayjs(time);
    return parsedTime.isAfter(dayjs().subtract(1, 'day'))
      ? parsedTime.isAfter(dayjs().subtract(1, 'hour'))
        ? dayjs().diff(parsedTime, 'minute') + ' minutes ago'
        : dayjs().diff(parsedTime, 'hour') + ' hours ago'
      : parsedTime.format('DD/MM/YYYY');
  };

  const getMedia = () => {
    const media = _.flattenDeep(feed.sourceObjects.map(s => s.sourceMedia.map(m => m.url)));
    if (media.length > 0) {
      return media[0];
    }
    return '';
  };

  const summaryType = Cookies.get('summaryType');

  return (
    <Card className="w-full">
      <CardHeader className="flex gap-3 justify-between">
        <div className="flex flex-row space-x-3">
          <div className="w-[75px] h-[75px] flex-shrink-0">
            <Image
              className="object-cover rounded-md"
              width={75}
              height={75}
              src={getMedia()}
              removeWrapper={true}
            />
          </div>
          <div className="flex flex-col ">
            <p className="text-md font-bold">{feed.title}</p>
            <div className="flex flex-row space-x-2 pt-2">
              <Chip color="default" radius="sm" variant="flat" size="sm">
                {category?.value}
              </Chip>
              <Chip color="default" radius="sm" variant="flat" size="sm">
                {feed.topic.query}
              </Chip>
              <Flag updateId={feed.id} />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-end space-y-3 ">
          <p className="pt-0">{getTimeString(feed.createdAt)}</p>
          <div className="flex flex-row">
            <AvatarGroup isBordered max={feed.sourceObjects.length} size="sm">
              {feed.sourceObjects.map(source => (
                <Tooltip key={source.url} color="default" content={source.url}>
                  <Avatar
                    isBordered
                    radius="full"
                    onClick={() => window.open(source.url)}
                    src={`https://www.google.com/s2/favicons?domain=${source.url}&sz=64`}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="flex space-x-2 pt-2 justify-between">
          {summaryType === 'summary' ? (
            <p>{feed.body}</p>
          ) : (
            <ul>
              {feed.points
                .filter(p => p !== '')
                .map((point, index) => (
                  <li key={index} className="flex  mb-2">
                    <span className="mr-2">•</span>
                    {point}
                  </li>
                ))}
            </ul>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default FeedComponent;
