import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  SharedSelection,
} from '@nextui-org/react';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from 'react';
import { Category, fetchKeywords, Keywords } from '../../services/api';
import { SearchIcon } from './Navbar';

const SearchModel = ({ isOpen, onOpenChange, categories, searchHandler, theme }: any) => {
  const [selectedKey, setSelectedKey] = React.useState<string>('formula1');
  const [selectedValue, setSelectedValue] = React.useState<string>('');
  const [keywords, setKeywords] = React.useState<Keywords[]>([]);

  const token = Cookies.get('token');

  const handleSelectionChange = (key: SharedSelection) => {
    if (key.currentKey === undefined) {
      setSelectedKey('Category');
      return;
    }
    getKeywords(key.currentKey);
    const v = categories.find((c: Category) => c.id.toString() === key.currentKey);
    setSelectedKey(v.value);
  };

  const handleSearch = async () => {
    const c = categories.find((c: any) => c.value === selectedKey);
    if (c === undefined) {
      return;
    }
    const id = c.id;
    await searchHandler(selectedValue, id);
  };

  const getKeywords = useCallback(
    async (id: string) => {
      const k = await fetchKeywords(id, token as string);
      setKeywords(k);
    },
    [token],
  );

  const handleKeywordClick = (e: any) => {
    setSelectedValue(e.target.innerText);
  };

  useEffect(() => {
    if (categories.length === 0) {
      return;
    }
    getKeywords(categories[0].id);
  }, [getKeywords, categories]);

  return (
    <Modal
      className={`w-screen ${theme}`}
      backdrop="opaque"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      closeButton={<></>}
      isDismissable={true}
    >
      <ModalContent>
        {onClose => (
          <div className="flex flex-col ">
            {
              <ModalHeader>
                <p>Top Topics in {selectedKey}</p>
              </ModalHeader>
            }

            <ModalBody>
              <div className="flex flex-row flex-wrap gap-2 ">
                {keywords.map((keyword, index) => (
                  <Button key={index} variant="flat" size="sm" onPress={handleKeywordClick}>
                    {keyword.word}
                  </Button>
                ))}
              </div>
              <div className="flex flex-row justify-between space-x-2 p-3 ">
                <Input
                  isClearable
                  radius="lg"
                  placeholder="Type to find a topic..."
                  onChange={e => setSelectedValue(e.target.value)}
                  value={selectedValue}
                  endContent={
                    <Button
                      isIconOnly
                      variant="light"
                      size="sm"
                      onClick={() => {
                        handleSearch();
                        onClose();
                      }}
                    >
                      <SearchIcon />
                    </Button>
                  }
                ></Input>
                <Dropdown className={`${theme}`}>
                  <DropdownTrigger>
                    <Button variant="bordered" className="capitalize">
                      {selectedKey}
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    variant="flat"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selectedKey}
                    onSelectionChange={handleSelectionChange}
                  >
                    {categories.map((category: any) => (
                      <DropdownItem key={category.id} className="capitalize">
                        {category.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </ModalBody>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SearchModel;
